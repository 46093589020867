import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';
import {
  AnimationOptions
} from 'ngx-lottie';
import {
  AnimationItem
} from 'lottie-web';

@Component({
  selector: 'app-covid-delay',
  templateUrl: 'covid-delay.component.html',
  styleUrls: ['../../pop-up/pop-up.component.css']
})

export class CovidDelayComponent implements OnInit {
  closeEffect: AnimationOptions;
  closeAnim: any;

  scrollEffect: AnimationOptions;
  scrollAnim: any;

  // scroll icon
  showScrollIcon = false;

  // window property
  clientHeight!: number;
  clientWidth!: number;
  typeBoxHeight!: number;
  closedIconSize = 200;

  @ViewChild('popup', {
    static: false
  }) el: ElementRef;
  @Output() toggleShow = new EventEmitter < boolean > ();

  constructor() {
    this.closeEffect = {
      path: '/assets/button/26175-close-button.json',
      renderer: 'svg',
      autoplay: true,
      loop: false
    };

    this.scrollEffect = {
      path: '/assets/15211-swipe-up-gesture.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
    };
  }

  close() {
    this.toggleShow.emit(false);

    let cName = 'covidNotiDelayed';
    let cValue = 'true';
    let cExpired = new Date('2020-04-30');

    document.cookie = cName + '=' + cValue + '; expires=' + cExpired + ';secure; path=/; SameSite=Strict;';
  }

  onScroll() {
    this.showScrollIcon = false;
  }

  checkScrollIcon() {
    setTimeout(() => {
      if (this.el.nativeElement.scrollHeight > this.el.nativeElement.offsetHeight) {
        this.showScrollIcon = true;
      }
    }, 250);
  }

  closeAnimation(anim: AnimationItem) {
    this.closeAnim = anim;
  }

  scrollAnimation(anim: AnimationItem) {
    this.scrollAnim = anim;
  }

  ngOnInit() {
    this.checkScrollIcon();

    this.clientHeight = window.innerHeight;
    this.clientWidth = window.innerWidth;

    if (this.clientHeight < 480 || this.clientWidth < 480) {
      this.closedIconSize = 150;
      this.typeBoxHeight = this.clientHeight - this.closedIconSize;
    } else {
      this.closedIconSize = 200;
      this.typeBoxHeight = this.clientHeight - this.closedIconSize;
    }
  }
}
