<div class="pop-up-bg" [style.width.px]="clientWidth" [style.height.px]="clientHeight" #popupParent>
  <ng-lottie class="close-btn" [options]="closeEffect" [width]="closedIconSize"
    (animationCreated)="closeAnimation($event)" (click)="close()"></ng-lottie>
  <div class="animated"
    [style.width.px]="clientWidth" [style.height.px]="clientHeight">
    <div id="pop-up">
      <div class="type-box scroll-able" [style.maxHeight.px]="typeBoxHeight"
        [ngStyle]="{'max-width': '768px', 'margin': '0 auto'}" #popup (scroll)="onScroll()">
        <p style="    
          padding: 20px 0;
          font-family: Quicksand, sans-serif;
          line-height: 1.4;
          font-size: 16px;
          margin: 0;">
          We regret to inform you that all studies scheduled to take place the week of March 23, 2020 – March 28, 2020
          have been postponed until further notice. This applies to all 4 locations (Chicago, Santa Ana, Plano, White
          Plains).
          <br><br>
          We sincerely apologize for any inconvenience this may have caused!
          <br><br>
        </p>
      </div>
    </div>
  </div>
  <ng-lottie *ngIf="showScrollIcon" class="scroll-icon" [options]="scrollEffect" [width]="600"
    (animationCreated)="scrollAnimation($event)"></ng-lottie>
</div>
