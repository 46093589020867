import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import {
  AnimationOptions
} from 'ngx-lottie';
import {
  AnimationItem
} from 'lottie-web';

@Component({
  selector: 'app-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['../pop-up/pop-up.component.css']
})

export class NotificationComponent implements OnInit {
  // ef effect
  closeEffect: AnimationOptions;
  closeAnim: any;

  scrollEffect: AnimationOptions;
  scrollAnim: any;

  // scroll icon
  showScrollIcon = false;
  showCloseButton = true;

  // window property
  typeBoxHeight!: number;
  closedIconSize!: string;

  isClosed = false;

  @ViewChild('popup', {
    static: false
  }) el!: ElementRef;
  @Output() toggleShow = new EventEmitter < boolean > ();

  constructor() {
    this.closeEffect = {
      path: '/assets/button/26175-close-button.json',
      renderer: 'svg',
      autoplay: true,
      loop: false
    };

    this.scrollEffect = {
      path: '/assets/15211-swipe-up-gesture_black.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
    };
  }

  close() {
    this.isClosed = true;

     setTimeout(() => {
       this.showCloseButton = false;
      this.toggleShow.emit(false);
    }, 1000);
  }

  onScroll() {
    this.showScrollIcon = false;
  }

  checkScrollIcon() {
    setTimeout(() => {
      if (this.el.nativeElement.scrollHeight > this.el.nativeElement.offsetHeight) {
        this.showScrollIcon = true;
      }
    }, 250);
  }

  closeAnimation(anim: AnimationItem) {
    this.closeAnim = anim;
  }

  scrollAnimation(anim: AnimationItem) {
    this.scrollAnim = anim;
  }

  initIconSize(w: number, h: number) {
    if (w < 480 || h < 480) {
      this.closedIconSize = '64px';
      this.typeBoxHeight = (h * 0.96) - 64;
    } else {
      this.closedIconSize = '72px';
      this.typeBoxHeight = (h * 0.96) - 72;
    }
  }

  ngOnInit() {
    this.checkScrollIcon();
    this.initIconSize(window.innerWidth, window.innerHeight);
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:orientationchange', ['$event'])
  onresize(e: any) {
    this.checkScrollIcon();
    this.initIconSize(e.target.innerWidth, e.target.innerHeight);
  }
}
