import {
  Component,
  HostListener,
  OnInit,
  Renderer2
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd
} from '@angular/router';
import {
  Title,
  Meta
} from '@angular/platform-browser';
import {
  NotificationService
} from './services/notification.services';
import {
  filter,
  map,
  mergeMap
} from 'rxjs/operators';
import {
  SEOService
} from './services/SEOService.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  isThisIE = false;
  isShowNotification = false;
  footer = document.querySelector("txr-sms-chat");
  isDragging = true;
  offsetX = 0;
  offsetY = 0;
  draggableElement: HTMLElement;
  clientWidth: number;

  // load javascript dynamically
  loadAPI: Promise < any > ;

  constructor(public router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private titleService: Title,
    private metaTagService: Meta,
    private renderer: Renderer2,
    private seoService: SEOService) {
    this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((r) => {
          while (r.firstChild) {
            r = r.firstChild;
          }
          return r;
        }),
        filter((r) => r.outlet === 'primary'),
        mergeMap((r) => r.data)
      )
      .subscribe((event) => {
        this.seoService.updateTitle(typeof event['title'] === 'undefined' || event['title'] === '' ? 'P&K Tester Community' : event['title']);

        // Updating Description tag dynamically with title
        this.seoService.updateDescription(typeof event['description'] === 'undefined' ? '' : event['description']);
        this.seoService.updateKeywords(typeof event['keywords'] === 'undefined' ? '' : event['keywords']);
      });

    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  toggleShow(event: boolean) {
    this.isShowNotification = event;
  }

  loadScript() {
    let isFound = false;
    const scripts = document.getElementsByTagName("script");

    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src')!.includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      const dynamicScripts = ['https://apis.google.com/js/platform.js'];

      for (let i = 0; i < dynamicScripts.length; i++) {
        let node = this.renderer.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = true;
        this.renderer.appendChild(document.head, node);
      }
    }
  }

  checkCookie(cname: string) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  runDraggable() {
    if (this.footer.shadowRoot) {
      if (this.footer.shadowRoot.querySelector(".txr-sms-chat     ")) {
        this.draggableElement = this.footer.shadowRoot.querySelector(".txr-sms-chat     ");

        this.draggableElement.addEventListener('mousedown', (e) => {
          this.isDragging = true;
          this.offsetX = e.offsetX;
          this.offsetY = e.offsetY;
          this.draggableElement.classList.add('dragging');
        });

        this.draggableElement.addEventListener('touchstart', (e) => {
          this.isDragging = true;
          this.offsetX = e.touches[0].clientX - this.draggableElement.getBoundingClientRect().left;
          this.offsetY = e.touches[0].clientY - this.draggableElement.getBoundingClientRect().top;
          this.draggableElement.classList.add('dragging');
        });

        document.addEventListener('mousemove', (e) => {
          if (this.isDragging) {
            this.draggableElement.style.left = `${e.clientX - this.offsetX}px`;
            this.draggableElement.style.top = `${e.clientY - this.offsetY}px`;
          }
        });

        document.addEventListener('touchmove', (e) => {
          if (this.isDragging) {
            this.draggableElement.style.left = `${e.touches[0].clientX - this.offsetX}px`;
            this.draggableElement.style.top = `${e.touches[0].clientY - this.offsetY}px`;
          }
        });
        
        document.addEventListener('mouseup', () => {
          this.isDragging = false;
          this.draggableElement.classList.remove('dragging');
        });

        document.addEventListener('touchend', () => {
          this.isDragging = false;
          this.draggableElement.classList.remove('dragging');
        });
      }
    }
  }

  changeAxisTR() {
    if (this.footer.shadowRoot) {
      if (this.footer.shadowRoot.querySelector(".txr-sms-chat     ")) {
        this.draggableElement = this.footer.shadowRoot.querySelector(".txr-sms-chat     ");

        if (this.clientWidth <= 480) {
          this.draggableElement.style.bottom = "50px";
        }
      }
    }
  }

  ngOnInit() {
    this.clientWidth = window.innerWidth;
    
    // this.runDraggable();
    this.changeAxisTR();

    this.notificationService.currentPopupNotication.subscribe(r => {
      this.isShowNotification = r;
    });

    // title and meta setting
    if (this.router.url === '/') {
      this.titleService.setTitle('Share your opinion and Get paid - P&K Tester Community');
      this.metaTagService.addTags([{
          name: 'description',
          content: 'The most popular spot online to earn cash and rewards for sharing your thoughts. Join millions who take paid online surveys and earn cash for their opinion. Cash Rewards.'
        },
        {
          name: 'author',
          content: 'P&K Research'
        },
        {
          name: 'keywords',
          content: 'Survey, Study, Test, PK Testing, PK Tester Community, PK Test, survey research company'
        }
      ]);
    }

    let url = window.location.href;
    let redirectUrlAddresses = [
      '/community/main/secondary',
      '/community/main/winners',
      '/community/main/referral/event',
      '/community/main/account/general',
      '/community/main/selfSchedule',
      '/community/main/thankyou',
      '/community/main/schedule/check',
      '/community/main/schedule/change',
      '/community/main/dossier'
    ];

    if (navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      this.isThisIE = true;
    }

    this.route.queryParams.subscribe((param: any) => {
      if (param.t != null && param.s != null && param.d != null) {
        if (window.location.href.indexOf('CommonAP.aspx') > -1) {
          this.router.navigate(['/community/main/schedule/self'], {
            queryParams: {
              s: param.s,
              t: param.t,
              d: param.d
            }
          });
        }
      } else if (param.t != null && param.s != null && param.k != null) { // kid register - do nothing
      } else if (param.t != null && param.s != null) { // others
        // from e-mail link(screener)
        if (window.location.href.indexOf('default.aspx') > -1) {
          if (param.s != null) {
            sessionStorage.setItem('s', param.s);
          }
          if (param.t != null) {
            sessionStorage.setItem('t', param.t);
          }
          if (param.d != null) {
            sessionStorage.setItem('d', param.d);
          }
          if (param.e != null) {
            sessionStorage.setItem('e', param.e);
          }

          this.router.navigate(['/community/login'], {
            queryParams: {
              s: param.s,
              t: param.t
            }
          });
        }

        if (url.indexOf('/community/main') > -1 && !redirectUrlAddresses.includes(url)) {
          this.router.navigate(['/community/main/screener']);
        } else if (url.indexOf('/community/join') > -1) {
          this.router.navigate(['/community/join/account']);
        } else if (url.indexOf('/community/login') > -1) {
          this.router.navigate(['/community/login']);
        }

      } else if (url.indexOf('/treg/') > -1) {
        this.router.navigate(['/Account/ConfirmEmail'], {
          queryParams: {
            t: url.slice(url.indexOf('Q0001=') + 6, url.length),
            r: param.R,
            e: param.E
          }
        });
      }

      // external screener
      if (window.location.href.indexOf('ExternalScr.aspx') > -1) {
        this.router.navigate(['/community/main/schedule/self'], {
          queryParams: {
            ex: btoa((Math.random() + 1).toString(36).substring(7)),
          }
        });
      }

      // register existed user
      if (param.u != null) {
        this.router.navigate(['/community/join/account'], {
          queryParams: {
            u: param.u
          }
        });
      }

      // register
      if (param.e != null && !this.router.url.includes('/community/join')) {
        this.router.navigate(['/community/login'], {
          queryParams: {
            s: param.s,
            t: param.t,
            e: param.e
          }
        });
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:orientationchange', ['$event'])
  onOrientationchange(e: any) {
    this.clientWidth = e.target.innerWidth;
  }
}

