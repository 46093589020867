import {
  Component,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-ie-user',
  templateUrl: './ie-user.component.html',
  styleUrls: ['./ie-user.component.css']
})
export class IeUserComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}
