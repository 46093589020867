import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NotificationService {
  // sources
  private popupNotication = new Subject<boolean>();

  // streams
  currentPopupNotication = this.popupNotication.asObservable();

  updatePopupNotication(o: boolean) {
    this.popupNotication.next(o);
  }
}
