import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-no-find',
  templateUrl: './page-no-find.component.html'
})
export class PageNoFindComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
    if (this.router.url.includes('community')) {
      this.router.navigateByUrl('/community/login');
    } else {
      this.router.navigateByUrl('/');
    }
  }

}
