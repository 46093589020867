import {
  bootstrapApplication,
  BrowserModule,
  provideClientHydration
} from '@angular/platform-browser';
import {
  NgModule
} from '@angular/core';
import {
  FormsModule
} from '@angular/forms';
import {
  HttpClientModule
} from '@angular/common/http';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
  LottieModule, LottieCacheModule
} from 'ngx-lottie';
import {
  SharedRootModule
} from './tester-community/shared/shared-root.module';

import {
  AppComponent
} from './app.component';

// PK Tester community site
import {
  PageNoFindComponent
} from './page-no-find/page-no-find.component';
import {
  IeUserComponent
} from './ie-user/ie-user.component';
import {
  NotificationComponent
} from './notification/notification.component';
import {
  NotificationService
} from './services/notification.services';
import {
  CovidDelayComponent
} from './notification/covid-delay/covid-delay.component';
import {
  SEOService
} from './services/SEOService.service';

// Lottie
export function playerFactory() {
  return import('../../node_modules/lottie-web/build/player/lottie_svg');
}

const routes: Routes = [{
    path: '',
    loadChildren: () => import('./pk-testing/pk-testing.module').then(mode => mode.PkTestingModule)
  }, {
    path: 'Account',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'account',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'community',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  },
  // landing pages
  {
    path: 'lp/index',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'columbia',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'cl',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'cf',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'gp',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'ppcads',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'card',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'tw',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'yp',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'fb',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'ffg',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'mrkt',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'mrkt/:id',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'bgc',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'lp/welcome',
    loadChildren: () => import('./tester-community/login/login.module').then(mode => mode.LoginModule)
  }, {
    path: 'CommonAP.aspx',
    component: AppComponent
  }, {
    path: 'ExternalScr.aspx',
    component: AppComponent
  },{
    path: 'default.aspx',
    component: AppComponent
  }, {
    path: '**',
    component: PageNoFindComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    NotificationComponent,
    // etc
    PageNoFindComponent,
    IeUserComponent,
    CovidDelayComponent
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'serverApp'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    LottieModule.forRoot({
      player: playerFactory
    }),
    LottieCacheModule.forRoot(),
    SharedRootModule,
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking'
})
  ],
  exports: [
    RouterModule
  ],
  providers: [
    NotificationService,
    SEOService,
    provideClientHydration()
  ],
  bootstrap: [AppComponent],
  schemas: []
})
export class AppModule {}
