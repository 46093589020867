<div class="pop-up-bg">
  <div class="close-bg"></div>
  <ng-lottie *ngIf="showCloseButton" class="close-btn" [options]="closeEffect" [width]="closedIconSize"
    [height]="closedIconSize" (animationCreated)="closeAnimation($event)" (click)="close()">
  </ng-lottie>
  <div id="close-line"></div>
  <div>
    <div [ngClass]="{'in': !isClosed, 'out': isClosed}">
      <div class="type-box scroll-able pop-content-div" [style.maxHeight.px]="typeBoxHeight" #popup (scroll)="onScroll()">
        <p class="font-t-s3 color-primary">
          Nothing is more important to us than the health and safety of our P&amp;K community: our clients, our staff
          and our respondents.
        </p>
        <p class="font-t-s5">
          &bull; From the onset of this situation, we have been monitoring the ongoing COVID-19 outbreak according to
          the guidelines and recommendations of the CDC (Centers for Disease Control). As more cases of the coronavirus
          (COVID-19) continue to spread around the world and within the United States, we would like to take this
          opportunity to let you know what extra steps we are taking at P&amp;K Research in response to this public
          health concern.
          <br /><br />
          &bull; We have shared out company-wide the Center of Disease Control (CDC) recommendations for preventative
          actions. Additionally, employees have been encouraged to stay home if they are not feeling well.
          <br /><br />
          &bull; We will continue to conduct in-person research as long as it is prudent and practical to do so. Our
          test administration staff is food safety certified, and sanitation has always been our priority. On top of our
          usual disinfecting protocols, respondents test areas (tables, computers, chairs, etc.) and lobby areas are now
          being wiped down with sanitizing wipes between each test session. A single-packaged hand wipe is set at every
          station for the consumers to use before they start their evaluation, and we have placed additional hand
          sanitizers in the common areas. We are also sanitizing door handles and light switches frequently.
          <br /><br />
          &bull; Furthermore, we have set up our testing rooms to accommodate the 6-feet social distancing guideline for
          all sessions, we have added questions to our respondent screeners regarding recent travel to the higher risk
          regions, and are requesting respondents not to report to our test centers if they are not feeling well.
          <br /><br />
          &bull; Should we receive notice of a coronavirus diagnosis within our workforce or communities, we will follow
          the recommended CDC and local public health guidelines.
          <br /><br />
          &bull; We are monitoring local, state, and federal guidelines and will continue to monitor the CDC and World
          Health Organization for updates on the spread of the COVID-19 virus and their recommended actions to prevent
          further contagion.
          <br /><br />
          &bull; We recognize that circumstances may change quickly and may require us to make changes to how we conduct
          business. We are developing contingency plans and adjusting next steps as developments unfold. These
          contingency plans include the option of transitioning from in-person to online for qualitative research and
          replacing central location testing with home-use testing for quantitative research. We will work closely with
          our clients to devise the best research alternatives in response to changing circumstances.
          <br /><br />
          &bull; Above all, we remain mindful of the utmost priority of ensuring as best we can the health and safety of
          our clients, respondents, and our staff.
          <br /><br />
        </p>
      </div>
    </div>
  </div>
  <ng-lottie *ngIf="showScrollIcon" class="scroll-icon" [options]="scrollEffect" width="200px"
    (animationCreated)="scrollAnimation($event)" ></ng-lottie>
</div>
